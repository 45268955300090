.notification-viewed {
    background: #f2f8ed;
}

#mi-write-notifications-table_wrapper {
    margin-top: 85px;
}

.set-as-viewed-btn {
    color: #a9a9a9;
    float: right;
    cursor: pointer;
    margin-right: 10px;
}

.notification-set-as-viewed {
    display: none;
    color: #a9a9a9;
    float: right;
    cursor: pointer;
}

.notification-block:hover .notification-set-as-viewed {
    display: block;
}

.notification-block {
    min-height: 80px;
    position: relative;
    padding: 9px 15px;
}

.dot {
    height: 13px;
    width: 13px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 2px;
    display: inline-block;
}

.dot-container {
    text-align: center;
}

.dot:hover {
    height: 17px;
    width: 17px;
    background-color: #bbb;
    border-radius: 50%;
    cursor: pointer;
    margin: 0px;
}

.notification-not-viewed {
    background: #f2f8ed;
}

.view-all-notification {
    min-height: 30px;
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
}

.view-all-notification hr {
    margin: 0 !important;
    padding-top: 10px;
}

.notification-block-empty {
    position: relative;
    padding: 9px 15px;
    min-height: 38px;
}

.close-notification-menu {
    float: right;
    color: darkgray;
    cursor: pointer;
}

.settings-notification-menu {
    float: right;
    color: darkgray;
    cursor: pointer;
    margin-right: 5px;
}

.notification-container .popover-title {
    font-weight: 700;
    font-size: 16px;
}

.notification-container .popover-content {
    padding: 0px !important;
    word-wrap: break-word;
}

.notification-container .popover {
    min-width: 500px !important;
}

.notification-menu-li {
    margin-right: -6px;
    margin-left: 4px;
}

.notification-block .notification-text {
    padding-left: 13px;
    font-size: small;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding-right: 15px;
}

.scrollable {
    overflow-y: auto;
    width: 100%;
}

@supports (-ms-ime-align:auto) {
    .scrollable:after {
        content: "";
        height: 16px;
        display: block;
    }
}

.scrollable {
    scrollbar-width: thin;
    scrollbar-color: #a9a9a9 #efefef;
}


.scrollable::-webkit-scrollbar-track {
    background-color: #efefef;
    width: 4px;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
    border: 1px solid transparent;
    background-clip: content-box;
}

.scrollable::-webkit-scrollbar {
    width: 8px;
}

.notifications-popover-loader {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
}
