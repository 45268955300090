th {
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 100;
    margin-bottom: 5px;
}

h1 {
    font-size: 300%
}

h2 {
    font-size: 250%
}

h3 {
    font-size: 200%
}

h4 {
    font-size: 150%
}

h5 {
    font-size: 125%
}

h6 {
    font-size: 100%
}

ul {
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

    ul.bullet-list {
        list-style: disc;
        margin: 5px;
        padding: 10px;
    }

    ul a {
        display: block;
    }

    ul.bullet-list a {
        text-decoration: underline;
        display: inline;
    }

hr {
    height: 1px;
    border: thin solid #dddddd;
    border-style: solid none none none;
    margin: 20px 0 20px 0
}

/* PAGE LAYOUT */

html {
    height: 100%;
}

body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#bodyWrapper {
    flex: 1 0 auto;
}

#footer {
    flex-shrink: 0;
}

/* HORIZONTAL LISTS */
.hlist-block > * {
    display: inline-block;
}

.hlist-float > * {
    float: left;
}

#footer {
    background-color: #999;
    color: white;
}

    #footer a {
        color: white;
    }

/* HELPFUL LINKS */
#helpful-links {
    background: #ededed;
    margin: 0;
    padding: 20px 15px 20px 15px;
    border-color: lightgray;
    border-width: thin;
    border-right: 0px;
    border-style: none solid none solid;
}

    #helpful-links h5 {
        padding: 10px 0 5px 0;
        color: #666;
        font-size: 1.4em;
    }

    #helpful-links li a {
        padding: 3px 0;
        font-size: 1em;
    }

    #helpful-links ul a {
        display: inline;
    }

/* BOOTSTRAP FIXES */
.caret.caret-reversed {
    border-top-width: 0;
    border-bottom: 4px solid #000000;
}

.input-group-btn, .btn, .form-control {
    font-size: inherit !important;
    vertical-align: middle;
}
/*.form-control {
    height: auto !important;
}*/

.dataTable thead th {
    border: none !important;
}

.dataTables_paginate {
    float: right
}

form:not(.form-horizontal) .field-validation-error {
    top: 1.7em !important;
}

select.input-validation-error + .field-validation-error {
    right: 20px !important;
}

/* FORMS */
label.required-field::after {
    content: "*";
    color: red;
}

fieldset {
    margin-top: 15px;
}

legend {
    border: none;
    margin-bottom: 10px;
}

.form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0;
}

.icon-only {
    line-height: 1em;
    padding-top: 0;
}

/* ERRORS/VALIDATION */
.required {
    color: red
}

.validation-summary-errors {
    background-image: url('../images/common/error_icon_yellow_sm.gif');
    background-repeat: no-repeat;
    background-position: 10px 50%;
    padding-left: 60px
}

    .validation-summary-errors span:first-child {
        font-weight: bold;
    }

    .validation-summary-errors ul {
        list-style: disc;
        padding-left: 20px;
        margin-top: 5px;
    }

.dropdown-menu li {
    cursor: pointer;
}

/* Printing */
@media print {
    /* Override Bootstrap putting in urls after anchors. */
    a[href]:after {
        content: none;
    }
}

/* Horizontal Tabs */
.tabs-left > .nav-tabs {
    border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: none;
}

.tab-content > .active,
.pill-content > .active {
    display: block;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    float: none;
}

    .tabs-left > .nav-tabs > li > a {
        min-width: 74px;
        margin-right: 0;
        margin-bottom: 3px;
    }

.tabs-left > .nav-tabs {
    float: left;
    border-right: 1px solid #ddd;
}

    .tabs-left > .nav-tabs > li > a {
        margin-right: -1px;
        -webkit-border-radius: 4px 0 0 4px;
        -moz-border-radius: 4px 0 0 4px;
        border-radius: 4px 0 0 4px;
    }

        .tabs-left > .nav-tabs > li > a:hover,
        .tabs-left > .nav-tabs > li > a:focus {
            border-color: #eeeeee #dddddd #eeeeee #eeeeee;
        }

    .tabs-left > .nav-tabs .active > a,
    .tabs-left > .nav-tabs .active > a:hover,
    .tabs-left > .nav-tabs .active > a:focus {
        border-color: #ddd transparent #ddd #ddd;
        *border-right-color: #ffffff;
    }

.breadcrumb {
    margin-bottom: 0;
}

.draft-practice-header {
    word-wrap: break-word;
    width: 100%;
}

.paginate_input {
    -webkit-user-select: auto !important;
}

.paginate_input_end {
    -webkit-user-select: auto !important;
}

/* Tables */
table td {
    vertical-align: middle !important;
    height: 100%;
}

/* Account Avatars */
.avatar-table-container .account-avatar {
    width: 50px;
}

.avatar-table-container .user-fullname {
    overflow: hidden;
    text-overflow: ellipsis;
}

.avatar-table-container {
    padding: 0;
    display: flex;
    align-items: center;
}

/* Define a size for FontAwesome 5.x XL fonts */
.fa-xl {
    font-size: 1.66666em;
}