html, h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0
}

h1, h2, h3, h4 {
    padding-bottom: 5px;
}

body {
    font: 14px Verdana, Arial, sans-serif;
    background-color: #FFF;
    color: black;
}

#content {
    padding: 20px 0;
}

.wrapper {
    margin: 0 auto;
}

/* HEADER TABS*/
#navbar-tabs a:before {
    vertical-align: -3px;
}

#login-link {
    padding: 10px 10px 0 0;
    display: block;
}

.account-avatar img {
    border-radius: 50%;
    width: 40px;
    height: auto;
}

#account-avatar {
    margin-top: 2px;
    width: 40px;
    height: 40px;
}

#account-avatar-image {
    margin-top: 2px;
    width: 40px;
    height: 40px;
}

.dataTables_paginate > .next.btn.btn-default.ml-s.mr-s {
    margin-right: 0;
}

/*
** Set overflow-wrap on .ProseMirror so that the editor width 
** is not unbounded when the user types a really long word.
*/
.cafe-rich-text-editor .content .ProseMirror {
    overflow-wrap: anywhere !important;
}
