@font-face{
	font-family:'MI-WS-Glyph';
	src: url(fonts/MI_WS_Glyph-Regular.eot);
	src: url('fonts/MI_WS_Glyph-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/MI_WS_Glyph-Regular.woff') format('woff');
	unicode-range: U+E001-E705;
	}

.micon{
	position: relative;
	top: 1px;
	display: inline-block;	
	font-family: MI-WS-Glyph;
}

.micon-home:before { content: '\e001' }
.micon-practice:before { content: '\e002' }
.micon-portfolio:before { content: '\e003' }
.micon-lessons:before { content: '\e004' }
.micon-students:before { content: '\e005' }
.micon-prompts:before { content: '\e006' }
.micon-reports:before { content: '\e007' }
.micon-schools:before { content: '\e008' }
.micon-users:before { content: '\e009' }
.micon-help:before { content: '\e101' }
.micon-mail:before { content: '\e102' }
.micon-lock:before { content: '\e103' }
.micon-students:before { content: '\e104' }
.micon-link:before { content: '\e105' }
.micon-document:before { content: '\e106' }
.micon-accessibility:before { content: '\e107' }
.micon-edit:before { content: '\e201' }
.micon-print:before { content: '\e202' }
.micon-search:before { content: '\e203' }
.micon-download:before { content: '\e204' }
.micon-share:before { content: '\e205' }
.micon-attach:before { content: '\e206' }
.micon-warning:before { content: '\e207'; color:#F8971D;}
.micon-info:before { content: '\e208' }
.micon-note:before { content: '\e301' }
.micon-time:before { content: '\e302' }
.micon-asl:before { content: '\e303' }
.micon-sound:before { content: '\e304' }
.micon-award1:before { content: '\e305'; color:#00853F; }
.micon-award2:before { content: '\e305'; color:#008BB0; }
.micon-award3:before { content: '\e305'; color:#B5121B; }
.micon-plagiarismo:before { content: '\e306'; color: #2E6423; }
.micon-teacher:before { content: '\e401'; color:#E58E1A; }
.micon-selfservice:before { content: '\e705'; color:#e6ad00; }
.micon-prepackaged:before { content: '\e402'; color:#008BB0; }
.micon-district:before { content: '\e403'; color:#00B259; }
.micon-school:before { content: '\e404'; color:#AF006E; }
.micon-argumentative:before { content: '\e405' }
.micon-informative-explanatory:before { content: '\e406' }
.micon-narrative:before { content: '\e407' }
.micon-constructed-response:before { content: '\e704'; padding-right: 3px; }
.micon-add-show:before { content: '\e408'; color:#00853F; }
.micon-enabled:before { content: '\e409'; color:#476c29; }
.micon-delete:before { content: '\e410'; color:#B5121B; }
.micon-disabled:before { content: '\e411'; color:#B5121B; }
.micon-hide:before { content: '\e412'; color:#B5121B; }
.micon-career:before { content: '\e501';}
.micon-edtech:before { content: '\e502'; }
.micon-ela:before { content: '\e503'; }
.micon-elp:before { content: '\e504'; }
.micon-finance:before { content: '\e505'; }
.micon-dance:before { content: '\e506'; }
.micon-music:before { content: '\e507'; }
.micon-theater:before { content: '\e508'; }
.micon-art:before { content: '\e509'; }
.micon-health:before { content: '\e510'; }
.micon-library:before { content: '\e511'; }
.micon-language:before { content: '\e512'; }
.micon-math:before { content: '\e513'; }
.micon-physed:before { content: '\e514'; }
.micon-science:before { content: '\e515'; }
.micon-indian:before { content: '\e516'; }
.micon-wish:before { content: '\e601'; color:#E58E25; }
.micon-star:before { content: '\e602'; color:#E58E25; }
.micon-star-dark:before { content: '\e603'; color:#E58E25; }
.micon-letter:before { content: '\e701'; color:#00467F; }
.micon-letter-open:before { content: '\e702'; color:#00467F; }
.micon-letter-sent:before { content: '\e703'; color:#00467F; }