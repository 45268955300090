.table-wrapper {
    overflow-x: auto;
}

.dataTable th.numeric, .dataTable td.numeric {
    text-align: center;
}

.dataTable th.sorting, .dataTable th.sorting_asc, .dataTable th.sorting_desc {
    cursor: pointer;
    padding-right: 16px;
}

.dataTable th.sorting {
    background: url(../images/common/tSorter-both.png) no-repeat center right;
}

.dataTable th.sorting_asc {
    background: url(../images/common/tSorter-down.png) no-repeat center right;
}

.dataTable th.sorting_desc {
    background: url(../images/common/tSorter-up.png) no-repeat center right;
}

/* DESCRIPTION EXPANDER ON PROMPTS .dataTable */
.dataTable td.description {
}

.dataTable td.description .left {
    margin-right: -40px;
    float: left;
    width: 100%;
    overflow: hidden;
    height: 2.7em;
}

.dataTable td.description .left .content {
    margin-right: 40px;
}

.dataTable td.description > div.expanded {
    height: auto;
}

.dataTable td.description .right {
    float: right;
    padding: 6px 0;
}

.dataTable td.description button {
    padding: 3px 5px;
}

.dataTables_info {
    float: left;
    margin-top: 10px;
}

fieldset.dataMultiView {
    border: 0;
    text-align: right;
    padding: 10px 0;
    clear: both;
}

fieldset.dataMultiView select {
    border: solid 1px #ccc;
    background-color: #f5f5f5;
    color: #333;
}

fieldset.dataMultiView .viewDivider {
    margin: 0 6px;
    color: #ccc;
}

/* proctor-scored essay categories */
.dataTable .proctor-scored {
    background: #ecffe9;
    border-left: 1px solid #b6d9ba;
}

/* Loader */
.dataTables_wrapper { position: relative; }
.dataTables_processing { position: absolute; background: #333 ; top: 50%;left: 50%;  margin-left: -25px; margin-top: -25px; opacity: .7; border-radius: 5px; padding: 5px; }
.dataTables_processing span { width:32px; height: 32px; display: block; background-image: url('../Images/loader.png'); }

td {
    overflow: hidden;
    text-overflow: ellipsis;
}

.borderedTable td, .borderedTable th {
    border-color: black !important;
    border-style: solid !important;
    border-width: 1px !important;
}