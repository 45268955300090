html[data-useragent*='MSIE 10.0'] #accessibility, html[data-useragent*='MSIE 9.0'] #accessibility, html[data-useragent*='MSIE 8.0'] #accessibility   {
  display:none;
}

body.contrast *:not([class^=icon-]):not(.lesson-image), body.contrast *:before {
    background: inherit !important;
    border-color: inherit !important;
    color: inherit !important;
}

.accessibility-container .popover .btn-group .btn {
    padding: 3px 6px 0px 1px;
}

.accessibility-container .popover .btn.selected {
    border-color: black;
    border-width: 3px !important;
    border-style: solid !important;
    border-radius: 10px !important;
}

.accessibility-container .popover hr {
    margin: 5px 0;
}

#font-sizes .btn span.accessibility-sprites {
    height: 43px;  
    display:inline-block;  
}
.icon-font-small.accessibility-sprites { background-position: 2px -1px ; width: 20px;}
.icon-font-medium.accessibility-sprites { background-position: -26px -2px; width: 25px;}
.icon-font-large.accessibility-sprites { background-position: -62px -0px; width: 35px;}


#font-sizes .btn, #contrast .btn, #overlays .btn, #extras .btn {
    background: white;
    border-style: none;
}

#contrast .btn span.accessibility-sprites {
    height: 50px;  
    width: 50px;
    display:inline-block;  
}

.icon-black-cream.accessibility-sprites { background-position: 2px -46px; }
.icon-black-light-blue.accessibility-sprites { background-position: -48px -46px; }
.icon-black-light-magenta.accessibility-sprites { background-position: -98px -46px; }
.icon-white-black.accessibility-sprites { background-position: -148px -46px; }
.icon-yellow-dark-blue.accessibility-sprites { background-position: -198px -46px; }
.icon-black-white.accessibility-sprites { background-position: -248px -46px; }

.accessibility .popover  {
    min-width: 365px;
}

#overlays .btn span.accessibility-sprites {
    display: inline-block;
    height: 45px;  
    width: 50px;  
}

.icon-green-overlay.accessibility-sprites { background-position: -0px -104px; }
.icon-pink-overlay.accessibility-sprites { background-position: -100px -104px; }
.icon-blue-overlay.accessibility-sprites { background-position: -150px -104px; }
.icon-yellow-overlay.accessibility-sprites { background-position: -200px -104px; }
.icon-aqua-overlay.accessibility-sprites { background-position: -50px -104px; }

#overlay {
    position: fixed;
    top: 0;
    bottom: 100%;
    left: 0;
    right: 0;
    opacity: .2;
    pointer-events: none;
}

.icon-line-reader.accessibility-sprites {
    background-position: -248px -110px;
    display:inline-block;
    height: 45px; 
    width: 50px;  
}

.icon-reset.accessibility-sprites {
    background-position: -113px 0;
    display: inline-block;
    height: 45px;
    width: 50px;
}

#lineReaderTop, #lineReaderBottom {
    position: fixed;
    width: 100%;
    opacity: .7;
    background: black;
}

#lineReaderTop {
    top: 0;
    bottom: 100%;
}

#lineReaderBottom {
    bottom: 0;
    top: 100%;
}

.accessibility-label {
    color: #444;
    font-size: 14px;
    display: table;
    margin: 0 auto;
    font-weight: 700;
}

.accessibility-sprites {
    background: url('images/accessibility-sprites.png')
}

.accessibility-reset-button {
    padding: 12px;
}

.accessibility-container .popover-title {
    font-weight:700;
    font-size: 16px;
}